import { Container } from "./style";
import { useState } from "react";

const Form = ({ setProducts, products }) => {
  const [code, setCode] = useState();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [price, setPrice] = useState();
  const [discount, setDiscount] = useState();

  const changeCode = (e) => {
    setCode(e);
  };

  const changeName = (e) => {
    setName(e);
  };
  const changeDescription = (e) => {
    setDescription(e);
  };
  const changePrice = (e) => {
    setPrice(e);
  };
  const changeDiscount = (e) => {
    setDiscount(e);
  };

  const submitForm = (e) => {
    e.preventDefault();
    const codeFormat = parseInt(code);
    const priceFormat = parseInt(price).toFixed(2);
    const discountFormat = parseInt(discount).toFixed(2);

    const objProduct = {
      code: codeFormat,
      name: name,
      description: description,
      price: priceFormat,
      discount: discountFormat,
    };

    setProducts([...products, objProduct]);
  };
  return (
    <Container>
      <h1>Adicionar produto:</h1>
      <form onSubmit={submitForm}>
        <input
          placeholder="code"
          value={code}
          onChange={(e) => changeCode(e.target.value)}
        ></input>
        <input
          placeholder="name"
          caluse={name}
          onChange={(e) => changeName(e.target.value)}
        ></input>
        <input
          placeholder="description"
          calue={description}
          onChange={(e) => changeDescription(e.target.value)}
        ></input>
        <input
          placeholder="price"
          value={price}
          onChange={(e) => changePrice(e.target.value)}
        ></input>
        <input
          placeholder="discount"
          value={discount}
          onChange={(e) => changeDiscount(e.target.value)}
        ></input>
        <button type="submit">Adicionar</button>
      </form>
    </Container>
  );
};

export default Form;
